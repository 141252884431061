import React, { useState } from "react"
import { Row, Col, Tabs, Tab, Nav, Modal } from "react-bootstrap"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import VideoPlayer from "../../../components/youTube-Video"


const videoREsourcesNep = [
    {
      id: "1",
      title: "Learn about Classroom App as Instructor and Student",
      videoUrl: "https://youtu.be/YTYy_egggXY?si=kQLvn6ZXUYLCWA6f",
      videoId: "YTYy_egggXY",
    },
    {
      id: "2",
      title: "Using Live-Classes App as Instructor and Student",
      videoUrl: "https://youtu.be/baRMUB7PcHk?si=4mXCibxxxwxhkg2t",
      videoId: "baRMUB7PcHk",
    },
    {
      id: "3",
      title: "Getting started with Fuse Classroom Mobile App",
      videoUrl: "https://youtu.be/d-ixyXNjbKM?si=F0DLPgAYhCznKWQP",
      videoId: "d-ixyXNjbKM",
    },
    {
      id: "4",
      title: "Fuse Classroom - Introduction",
      videoUrl: "https://youtu.be/lAAn1p2zJ3w?si=DCzHiKUFUbADUBOr",
      videoId: "lAAn1p2zJ3w",
    },
]


const videoREsourcesEng = [
  {
    id: "1",
    title: "Using Live-Classes App as Instructor and Student",
    videoUrl: "https://youtu.be/gcmZoO8Za7w?si=XFdLl6Pk-AFwCRrZ",
    videoId: "gcmZoO8Za7w",
  },
  {
    id: "2",
    title: "Getting Started with Fuse Classroom Mobile App",
    videoUrl: "https://youtu.be/vdDfA37cInY?si=ZE8nhpYb8aic98bq",
    videoId: "vdDfA37cInY",
  },
  {
    id: "3",
    title: "Classroom App Walkthrough-Student",
    videoUrl: "https://youtu.be/iuqyht-IaAg?si=cai8Dnvao-D0je21",
    videoId: "iuqyht-IaAg",
  },
  {
    id: "4",
    title: "Classroom App Walkthrough-Instructor",
    videoUrl: "https://youtu.be/B7SnZTIajM0?si=udtkiM-GoDUNQuhH",
    videoId: "B7SnZTIajM0",
  },
]

const Videos = () => {
  const [show, setShow] = useState(false)
  const [currentData, setCurrentData] = useState(null)

  const handleModal = (list) => {
    setShow(!false)
    console.log({list});
    setCurrentData(list)
  }

  // render() {

  return (
    <Layout>
      <SEO title={"Videos"} titleDesc={"-"} titleKeywords={"-"} fbDes={"-"} twitDesc={"-"} seoLink={"/"} />

      {/* top section */}
      <section role="top--section" className="pb-5">
        <article className="container">
          <Row className="align-items-center mt-5">
            <Col lg={12} className="text-center">
              <h1 className="font-weight-bold">Walkthrough Videos</h1>
              <p className="h5 font-weight-normal mt-3">Select your preferred Language</p>
            </Col>
          </Row>

          <Tab.Container id="resource-video-language" defaultActiveKey="nepali">
            <Nav variant="pills" className="justify-content-center mt-3 resource-video-tab">
              <Nav.Item>
                <Nav.Link eventKey="nepali" className="btn font-weight-semibold mr-3">
                  Nepali
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="english" className="btn font-weight-semibold">
                  English
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="nepali">
                <Row className="justify-content-center mt-5">
                  
                  {videoREsourcesNep.map((list) => {
                    return(<>
                      <Col md={4} className="my-3" key={list.id}>
                        <div className="text-center h-100">
                          <div className="video-block" onClick={() => handleModal(list)}>
                            <img alt={list.tile} src="/images/thumbimg.webp" />
                            <div className="play-button" />
                          </div>
    
                          <div className="h4 mt-3 text-green font-weight-semibold">
                            {list.title}
                          </div>
                        </div>
                      </Col>
                    </>)
                  } )}
                </Row>
              </Tab.Pane>

              <Tab.Pane eventKey="english">
                <Row className="justify-content-center mt-5">
                  
                  {videoREsourcesEng.map((list) => {
                    return(<>
                      <Col md={4} className="my-3" key={list.id}>
                        <div className="text-center h-100">
                          <div className="video-block" onClick={() => handleModal(list)}>
                            <img alt={list.tile} src="/images/thumbimg.webp" />
                            <div className="play-button" />
                          </div>
    
                          <div className="h4 mt-3 text-green font-weight-semibold">
                            {list.title}
                          </div>
                        </div>
                      </Col>
                    </>)
                  } )}
                  
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </article>
      </section>
      {/* top section end */}

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Body className="p-0">
            
            <div className="youtube-player">
              <VideoPlayer
                videoUrl={currentData?.videoUrl}
                videoId={currentData?.videoId}
                playsInline={true}
              />
            </div>
        </Modal.Body>
      </Modal>
    </Layout>
  )
  // }
}

export default Videos
